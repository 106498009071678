export default {
  listado: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Auth/Login'),
    },
    {
      path: '/',
      name: 'Principal',
      component: () => import('@/layouts/Container'),
      redirect: { name: 'Inicio' },
      meta: { requiresAuth: true },
      children: [
        {
          path: 'dashboard',
          name: 'Inicio',
          component: () => import('@/views/Dashboard/Index'),
        },
        {
          path: 'changepass',
          name: 'changepass',
          component: () => import('@/views/Auth/ChangePass'),
        },
        {
          path: 'usuarios',
          meta: { label: 'Usuarios', is_admin: true },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              name: 'Listado de Usuarios',
              path: '',
              component: () => import('@/views/Usuarios/UsuariosTable'),
            },
            {
              path: 'create',
              name: 'Nuevo Usuario',
              component: () => import('@/views/Usuarios/UsuariosCreate'),
            },
            {
              path: 'edit/:id',
              name: 'Editar Usuario',
              component: () => import('@/views/Usuarios/UsuariosEdit'),
            },
            {
              path: 'clearpass/:id',
              name: 'Blanquear Usuario',
              component: () => import('@/views/Usuarios/UsuariosClearPass'),
            },
          ],
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/views/System/PageNotFound'),
    },
  ],
}
