import Vue from 'vue'
import VeeValidate from 'vee-validate'

const VueValidationEs = require('vee-validate/dist/locale/es')

Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields',
  locale: 'es',
  dictionary: { es: VueValidationEs },
})
